import React from 'react';
import {FormattedMessage} from 'react-intl';
import { Field, useField, useFormikContext } from "formik";
import { currentLang } from '../utils/miscUtils';

import Flatpickr from "react-flatpickr";
import { French } from 'flatpickr/dist/l10n/fr'

import messages_en from "../translations/en.json";
import messages_fr from "../translations/fr.json";

import 'flatpickr/dist/flatpickr.min.css'

const messages = currentLang() === 'fr' ? messages_fr : messages_en;

/**
 * labels must be pre-translated in originating component because this file's components use loops/callbacks to iterate over collections of items and you cannot use useIntl() inside
 * these loops.  Also, certain components may choose to dynamically show/hide content, and useIntl() cannot be included inside components that dynamically show/hide.
 * 
 * Recommendation is to perform all possible label translations for your component into an object at the top of the component and pass in translated label into these fields.
 */

const fieldError = (meta, label) => {
	//For required field errors, include the field name to satisfy accessibility
	//TODO: should be part of the component props e.g. <TextFieldWithLabel ... includeLabelInError) />
	const includeLabelWithError = messages['common.required'] === meta.error && !!label;
	// const includeLabelWithError = false;

	if(meta?.error && meta?.touched) {
		return <strong className="error">
			<span className="label label-danger" style={{
				whiteSpace: 'normal',
				display: 'flow-root',
				textAlign: 'left',
				width: 'fit-content'
			}}>
				<span className="prefix">
					<FormattedMessage id="globalAlert.error"/>
					<FormattedMessage id="wet.colon"/>&nbsp;
				</span>
				{meta.error}
				{ includeLabelWithError && ` (${label})`}
			</span>
		</strong>
	}
	return <></>
}

/* 
	This isn't a "real" Formik input but rather Flatpickr component hacked to fit within a Formik form
	It's faked by just detecting changes and then setting the corresponding Formik value with it
*/
export const DateTimeFieldWithLabel = ({label, ...props}) => {
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(props);
	
	// Workaround to ensure a reset command works 
	// ! This is works with the default Formik 'enableReinitialize={false}'
	// ! if you change it to true: when your form has errors on submitting (it will reset the form)
	let resetForm = false;
 	if(field?.value?.length === 0) {
		resetForm = true;
	}

	return (
		<div style={props.style ? props.style : {float:"left", width:"100%"}}  className={props.wrapperClassName ? props.wrapperClassName : "form-group col-md-12"}>
			<label htmlFor={props.id || props.name} className={props.labelClassName ? props.labelClassName+(props.required ? " required" : "") : "control-label col-md-3"+(props.required ? " required" : "")}>
				{label} {props.required ? <strong className="required"><FormattedMessage id="common.required.inBrackets"/></strong> : <></>}
			</label>
            <div className={props.inputWrapperClassName ? props.inputWrapperClassName : meta && meta.touched && meta.error ? "col-md-4 has-error" : "col-md-4"}>	            
				<Flatpickr
					data-testid={props['data-testid']}
					data-enable-time
					style={{backgroundColor: 'white'}}
					name={props.name}
					id={props.id}
					className={meta && meta.touched && meta.error ? " has-error form-control full-width error" : "form-control full-width"}
					options={{
						minDate: props.min,
						maxDate: props.max,
						locale: currentLang() === 'fr' ? French :  null,
						allowInvalidPreload: true
					}}					
					placeholder={props.placeholder}
					onChange={(dates, dateString, instance) => {
						const formattedDate = instance.formatDate(dates[0], "Y-m-d\\TH:i");
						setFieldValue(props.name, formattedDate);
					}}
					value={resetForm ? '' : null}
				/>
			</div>
			{fieldError(meta, label)}
		</div>
	);
};

export const TextFieldWithLabel = ({label, wrapperClassName, wrapperStyle, labelColClass, inputColClass,  ...props}) => {
    const meta = useField(props)[1];
    return (
        <div style={wrapperStyle ? wrapperStyle : {float:"left", width:"100%"}} className={wrapperClassName ? wrapperClassName : "form-group col-md-12"}>
            <label htmlFor={props.id || props.name} className={(!!labelColClass ? labelColClass : "")+" control-label"+(props.required ? " required" : "")}>
                {label} {props.required ? <strong className="required"><FormattedMessage id="common.required.inBrackets"/></strong> : <></>}
            </label>
            <div className={(!!inputColClass ? inputColClass : "")}>
                <Field 
					data-testid={props['data-testid']} 
					disabled={props.disabled} 
					onPaste={props.onPaste} 
					id={props.id} 
					name={props.name} 
					type="text" 
					placeholder={props.placeholder} 
					className={meta && meta.touched && meta.error ? " has-error form-control full-width error" : "form-control full-width"} 
					maxLength={props.maxLength}
				/>
            </div>
            {fieldError(meta, label)}
        </div>
    );
};

export const TextAreaFieldWithLabel = ({label, wrapperClassName, wrapperStyle, numOfRows, numOfCols, ...props}) => {
	const meta = useField(props)[1];
	return (
	    <div style={wrapperStyle ? wrapperStyle : {}} className={wrapperClassName ? wrapperClassName : "form-group col-md-12"}>
	        <label htmlFor={props.id || props.name} className={"control-label"+(props.required ? " required" : "")}>
	            {label} {props.required ? <strong className="required"><FormattedMessage id="common.required.inBrackets"/></strong> : <></>}
	        </label>
	        <div>
	            <Field data-testid={props['data-testid']} disabled={props.disabled} onPaste={props.onPaste} id={props.id} name={props.name} as="textarea" placeholder={props.placeholder} className={meta && meta.touched && meta.error ? " has-error form-control full-width error" : "form-control full-width"} rows={numOfRows} cols={numOfCols} maxLength={props.maxLength} />
	        </div>
			{fieldError(meta, label)}
	    </div>
	);
};

export const SelectFieldWithLabel = ({label, wrapperClassName, wrapperStyle, fieldClassName, labelClassName: propLabelClassName, options, onChange, ...props}) => {
	const meta = useField(props)[1];
	const onChangeProps = onChange ? { onChange } : {};

	const baseClass = propLabelClassName || "control-label col-md-3";
	const requiredClass = props.required ? "required" : "";
	const labelClassName = `${baseClass} ${requiredClass}`;

	return (
	    <div style={wrapperStyle ?? {float:"left", width:"100%"}} className={wrapperClassName ?? "form-group col-md-12"}>
	        <label htmlFor={props.id || props.name} className={labelClassName}>
	            {label} {props.required && <strong className="required"><FormattedMessage id="common.required.inBrackets"/></strong>}
	        </label>
	        <div className={fieldClassName ? 
				fieldClassName : 
				!fieldClassName && !!label ? 
					"col-md-4" : 
					"col-md-12 col-sm-12 col-xs-12"
			}>
	            <Field data-testid={props['data-testid']} disabled={props.disabled} {...onChangeProps} id={props.id} name={props.name} as="select" className={`form-control full-width ${meta?.error && meta?.touched && 'has-error error'}`}>
	                {options?.map(function(option, index) {
	                    return (
	                        <option key={(this.id || this.name)+"-option-"+index} value={option.value}>{option.label || option.value}</option>
	                    );
	                }, props)}
	            </Field>
	        </div>
	        {fieldError(meta, label)}
	    </div>
	);
}

export const RadioButtonsWithLabel = ({label, options, strongLabel, containerStyle, containerId, fieldClassName, mrgnBottom, colMd4, colMd8, radioSpacing, ...props}) => {
	const meta = useField(props)[1];
	return (
	    <div className={`form-group col-md-12 ${props.className || ''}`} style={containerStyle} id={containerId}>
	        <label htmlFor={props.name || props.id} className={"control-label col-md-"+(colMd8 ? "8" : "4")+(props.required ? " required" : "")}>
	            {!!label ? label : <></>}{" "} 
				{props.required && <strong className="required"><FormattedMessage id="common.required.inBrackets"/></strong> }
			</label>
			{/* <div className={colMd4 ? "gc-chckbxrdio col-md-4" : "gc-chckbxrdio"} style={{ marginLeft:20, position: 'relative', display: 'block'}}> */}
			<div className={colMd4 ? "gc-chckbxrdio col-md-4" : "gc-chckbxrdio"}>
				<ul>
					{ options?.map(function(option, index) {
						const radioId = `${props.name}-${index}`;
						return (
							<li key={radioId} className="radio" style={{marginBottom: radioSpacing || mrgnBottom}}>
								<Field 
									disabled={props.disabled} 
									type="radio" 
									name={this.name || this.id} 
									id={radioId} 
									className={fieldClassName} 
									value={option.value} 
								/>
								<label htmlFor={radioId} className={option.label && option.required ? "required" : ""}>{strongLabel ? <strong>{option.label || option.value}</strong> : option.label || option.value}{option.required ? <strong className="required"><FormattedMessage id="common.required.inBrackets"/></strong> : null}</label>
							</li>
						);
					}, props)}
				</ul>
			</div>
			{fieldError(meta, label)}
	    </div>
	);
}

export const CheckboxWithLabel = ({label, options, hideMainLabel, ...props}) => {
	const [, meta] = useField(props);
	const idName = props.id || props.name;

	return (
		<div className="form-group col-md-12">
			{/* Note: this label was wrapping all the checkboxes at first but that was causing really strange issues with formik not binding properly, just leaving this here in case someone comes looking from another related app */}
			{!hideMainLabel && 
				<label htmlFor={idName} className={"control-label col-md-10" + (label && props.required && " required")}>
					{label} {label && props.required && <strong className="required"><FormattedMessage id="common.required.inBrackets"/></strong>}
				</label>
			}
			<div id={props.id} className="checkbox gc-chckbxrdio" style={{marginLeft:20}}>
				<ul className="list-unstyled lst-spcd-2">
					{ options?.map(function(option, index) {
						return (
							<li key={`${idName}-${index}`} className="checkbox" >
								<Field
									disabled={props.disabled}
									type="checkbox"
									name={props.name || props.id}
									id={`${idName}-${index}`}
									value={option.value}
									onClick={() => option.onClick ? option.onClick(true) : {}} 
								/>
								<label htmlFor={ (idName) + '-' + index } className={ option.label && option.required && 'required'} >
									{option.label || option.value}{' '}
									{option.label && option.required && 
										<strong className="required">
											<FormattedMessage id="common.required.inBrackets" />
										</strong>
									}
								</label>
							</li>
						);
					}, props)}
					{fieldError(meta, label)}
				</ul>
			</div>
		</div>
	);
}
